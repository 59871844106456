import React, { useState } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { useTranslation } from 'react-i18next';

import { MainWrapper, columnProps, drodownStyles, footerProps } from './styles';
import { ICreateNewLocationProps } from './interfaces';

import { DatePicker, Dropdown, IDropdownOption } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { createProject, projectsReducerValues } from '../../reducer';
import { commonTools } from '../../../../tools/commonTools';
import { useAppDispatch } from '../../../../store';
import { IProject } from '../../interfaces';
import { log } from 'console';
import { DateTime } from 'luxon';
/**
 * Компонент для добавления новой локации.
 *
 * @param onOKClick Внешний обработчик кнопки ОК.
 */
export const NewProjectModal = ({ closeModal }: ICreateNewLocationProps) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const dispatch = useAppDispatch();

    const now: Date = new Date();
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [alias, setAlias] = useState('');
    const [selectedOwner, setSelectedOwner] = useState<number | null>(null);
    const { owners } = useSelector(projectsReducerValues);

    // const disabled = !validateFields({ name, alias }) || (cloneChecked && (!cloneId || !locationsById[cloneId]));

    const onNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setName(newValue || '');
        const translitted = commonTools.translit(newValue || '');
        setAlias(() => translitted);
    };

    const onAliasChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setAlias(newValue || '');
    };

    const onChangeDatePicker = (date: Date | null | undefined) => {
        date && setStartDate(date);
    };

    const onCreateClick = () => {
        if (selectedOwner && startDate) {
            const data: Omit<IProject, 'id' | 'date_to'> = {
                name,
                alias,
                date_from: DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd'),
                owner_id: selectedOwner,
            };
            dispatch(createProject(data));
            closeModal();
        }
    };

    const ownerOptions = owners.map((item) => ({ key: item.id, text: item.name }));

    const selectOwner = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
        option && setSelectedOwner(() => Number(option.key));
    };
    const buttonDisabled = !name || !alias || !selectedOwner || !startDate;

    return (
        <MainWrapper>
            <Stack {...columnProps}>
                <Dropdown
                    required
                    selectedKey={selectedOwner}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={selectOwner}
                    placeholder={t('Select an owner')}
                    options={ownerOptions}
                    styles={drodownStyles}
                />

                <TextField label={t('Project name')} value={name} onChange={onNameChange} required />
                <TextField label={t('Alias')} value={alias} onChange={onAliasChange} required />
                <DatePicker label={t('Start date')} id="dateFrom" onSelectDate={onChangeDatePicker} value={startDate} />
            </Stack>
            <Stack {...footerProps}>
                <Stack.Item align={'end'}>
                    <PrimaryButton
                        text={t('Create')}
                        onClick={onCreateClick}
                        allowDisabledFocus
                        disabled={buttonDisabled}
                    />
                </Stack.Item>
            </Stack>
        </MainWrapper>
    );
};
